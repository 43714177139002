import "./App.css";
import Portfolio from "./components/Portfolio";
import PortfolioScroll from "./components/PortfolioScroll";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ThemeContext } from "./contexts/themeContext";
import { useState } from "react";
import Intro from "./components/Intro";
import { allThemes } from "./theme/themes";

function App() {
  const [portfolio, setPortfolio] = useState(false);
  const [options, setOptions] = useState([0, 0, 0]);
  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: allThemes[options[1]].themeFont,
      },
    },
  });
  const openPortfolio = () => {
    setPortfolio(!portfolio);
  };

  return (
    <ThemeContext.Provider value={{ options, setOptions }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Intro onPortfolio={openPortfolio} onClose={openPortfolio} />

        {portfolio ? (
          !options[0] ? (
            <PortfolioScroll onClose={openPortfolio} />
          ) : (
            <Portfolio onClose={openPortfolio} />
          )
        ) : (
          ""
        )}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
